'use client';
import styled from 'styled-components';

export const PasswordResetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const PasswordResetFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 650px;
  padding: ${props => props.theme.spacing(0, 6)};
  border-radius: 35px;
  margin-bottom: ${props => props.theme.spacing(8)};
  background-color: ${props => props.theme.colors.background.lightest};
`;

export const Subheading = styled.p`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${props => props.theme.spacing(3, 0, 7)};
`;

export const PasswordResetForm = styled.form`
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 512px;

  > div {
    width: inherit;
  }
`;

export const RecaptchaContainer = styled.div`
  display: flex;
  margin-top: ${props => props.theme.spacing(3)};
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: ${props => props.theme.spacing(8)};
  margin-bottom: ${props => props.theme.spacing(12)};
  max-width: 336px;

  > div {
    width: 100%;
  }
  > button {
    width: 100%;
  }
`;

export const HeadingContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing(8)};
  margin-top: ${props => props.theme.spacing(13)};
`;

export const InputContainer = styled.div`
  padding-bottom: 24px;
`;
